const withLoading = async (asyncFunction, startLoading, stopLoading) => {
  startLoading();
  try {
    await asyncFunction();
  } catch (error) {
    console.error(error);
  } finally {
    stopLoading();
  }
};

export default withLoading;
